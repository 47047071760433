<template>
  <div>
    <PowerFulTable
      ref="PFTable"
      :columns="columns"
      :data="list"
      :operates="operates"
      :surplusHeight="48"
      @sortCustom="handleSortCustom"
    />

    <el-pagination
      v-if="Number(total) !== 0"
      background
      layout="->, prev, pager, next"
      :total="total"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved, import/extensions
import PowerFulTable from '@/components/table/powerful-table.vue'
import { alertHistoryHeader, alertHistoryOperate } from './index-data'

export default {
  components: {
    PowerFulTable
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      columns: alertHistoryHeader,
      listQuery: {
        orderBy: '',
        sort: ''
      },
      operates: alertHistoryOperate
    }
  },
  activated() {
    this.$nextTick(() => this.$refs.PFTable.$refs.multipleTable.doLayout())
  },
  methods: {
    handleCurrentChange(e) {
      this.listQuery.pageNo = e
      this.$emit('get-list', this.listQuery)
    },
    handleSortCustom({ prop, order }) {
      this.listQuery.orderBy = prop
      this.listQuery.sort = order ? order.replace('ending', '') : ''
      this.$emit('get-list', this.listQuery)
    }
  }
}
</script>
